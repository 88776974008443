import { execute, ApolloLink, FetchResult } from 'apollo-link';
import { ExecutionResult, DocumentNode } from 'graphql';
import { Cache } from 'apollo-cache';
import {
  getDefaultValues,
  getOperationDefinition,
  getOperationName,
  hasDirectives,
  graphQLResultHasError,
  hasClientExports,
  removeConnectionDirectiveFromDocument,
  canUseWeakMap,
} from 'apollo-utilities';

import { invariant, InvariantError } from 'ts-invariant';

import { isApolloError, ApolloError } from '../errors/ApolloError';
import { Observer, Subscription, Observable } from '../util/Observable';
import { QueryWithUpdater, DataStore } from '../data/store';
import { MutationStore } from '../data/mutations';
import { QueryStore, QueryStoreValue } from '../data/queries';

import {
  QueryOptions,
  WatchQueryOptions,
  SubscriptionOptions,
  MutationOptions,
  ErrorPolicy,
} from './watchQueryOptions';
import { ObservableQuery } from './ObservableQuery';
import { NetworkStatus, isNetworkRequestInFlight } from './networkStatus';
import {
  QueryListener,
  ApolloQueryResult,
  FetchType,
  OperationVariables,
} from './types';
import { LocalState } from './LocalState';
import { asyncMap, multiplex } from '../util/observables';
import { isNonEmptyArray } from '../util/arrays';

const { hasOwnProperty } = Object.prototype;

export interface QueryInfo {
  listeners: Set<querylistener>;
  Vô hiệu: Boolean;
  newData: Cache.DiffResult<any> | không;
  tài liệu: DocumentNode | không;
  lastRequestId: số;
  Bản đồ đi từ queryId đến người quan sát cho truy vấn do watchQuery phát hành. Chúng tôi sử dụng
  những điều này để theo dõi các truy vấn trên chuyến bay và lỗi trên các quan sát viên liên quan
  với họ trong trường hợp có một số hành động khử tabalizing (ví dụ: đặt lại cửa hàng Apollo).
  observableQuery: ObservableQuery<any> | không;
  đăng ký: Đặt<subscription>;
  hủy?: () => khoảng trống;
}

lớp xuất QueryManager<tstore> {
  public link: ApolloLink;
  public mutationStore: MutationStore = new MutationStore();
  public queryStore: QueryStore = new QueryStore();
  public dataStore: DataStore<tstore>;
  chỉ đọc công khai giả địnhKết quả bất biến: boolean;

truy vấn riêng tưTrùng lặp: boolean;
  khách hàng cá nhânNhận thức: Ghi lại<string, string=""> = {};
  private localState: LocalState<tstore>;

private onBroadcast: () => void;

ssrMode riêng: boolean;

Chúng ta đừng bắt đầu từ con số 0 để tránh đau đớn với kiểm tra xấu
  id riêng = 1;

XXX hợp nhất với ObservableQuery nhưng điều đó cần được mở rộng để hỗ trợ các đột biến và
  đăng ký là tốt
  truy vấn riêng tư: Bản đồ<string, QueryInfo=""> = new Map();

  // A map of Promise reject functions for fetchQuery promises that have not
  // yet been resolved, used to keep track of in-flight queries so that we can
  // reject them in case a destabilizing event occurs (e.g. Apollo store reset).
  // The key is in the format of `query:${queryId}` or `fetchRequest:${queryId}`,
  // depending on where the promise's rejection function was created from.
  private fetchQueryRejectFns = new Map<string, Function="">();

  constructor({
    link,
    queryDeduplication = false,
    store,
    onBroadcast = () => undefined,
    ssrMode = false,
    clientAwareness = {},
    localState,
    assumeImmutableResults,
  }: {
    link: ApolloLink;
    queryDeduplication?: boolean;
    store: DataStore<tstore>;
    onBroadcast?: () => void;
    ssrMode?: boolean;
    clientAwareness?: Ghi lại<string, string="">;
    localState?: LocalState<tstore>;
    assumeImmutableResults?: boolean;
  }) {
    this.link = link;
    this.queryDeduplication = queryDeduplication;
    this.dataStore = store;
    this.onBroadcast = onBroadcast;
    this.clientAwareness = clientAwareness;
    this.localState = localState || new LocalState({ cache: store.getCache() });
    this.ssrMode = ssrMode;
    this.assumeImmutableResults = !!assumeImmutableResults;
  }

  /**
   * Call this method to terminate any active query processes, making it safe
   * to dispose of this QueryManager instance.
   */
  public stop() {
    this.queries.forEach((_info, queryId) => {
      this.stopQueryNoBroadcast(queryId);
    });

    this.fetchQueryRejectFns.forEach(reject => {
      reject(
        new InvariantError('QueryManager stopped while query was in flight'),
      );
    });
  }

  public async mutate<t>({
    mutation,
    variables,
    optimisticResponse,
    updateQueries: updateQueriesByName,
    refetchQueries = [],
    awaitRefetchQueries = false,
    update: updateWithProxyFn,
    errorPolicy = 'none',
    fetchPolicy,
    context = {},
  }: MutationOptions): Promise<fetchresult<t>> {
    invariant(
      mutation,
      'mutation option is required. You must specify your GraphQL document in the mutation option.',
    );

    invariant(
      !fetchPolicy || fetchPolicy === 'no-cache',
      "fetchPolicy for mutations currently only supports the 'no-cache' policy"
    );

    const mutationId = this.generateQueryId();
    mutation = this.transform(mutation).document;

    this.setQuery(mutationId, () => ({ document: mutation }));

    variables = this.getVariables(mutation, variables);

    if (this.transform(mutation).hasClientExports) {
      variables = await this.localState.addExportedVariables(mutation, variables, context);
    }

    // Create a map of update queries by id to the query instead of by name.
    const generateUpdateQueriesInfo: () => {
      [queryId: string]: QueryWithUpdater;
    } = () => {
      const ret: { [queryId: string]: QueryWithUpdater } = {};

      if (updateQueriesByName) {
        this.queries.forEach(({ observableQuery }, queryId) => {
          if (observableQuery) {
            const { queryName } = observableQuery;
            if (
              queryName &&
              hasOwnProperty.call(updateQueriesByName, queryName)
            ) {
              ret[queryId] = {
                updater: updateQueriesByName[queryName],
                query: this.queryStore.get(queryId),
              };
            }
          }
        });
      }

      return ret;
    };

    this.mutationStore.initMutation(
      mutationId,
      mutation,
      variables,
    );

    this.dataStore.markMutationInit({
      mutationId,
      document: mutation,
      variables,
      updateQueries: generateUpdateQueriesInfo(),
      update: updateWithProxyFn,
      optimisticResponse,
    });

    this.broadcastQueries();

    const self = this;

    return new Promise((resolve, reject) => {
      let storeResult: FetchResult<t> | null;
      let error: ApolloError;

      self.getObservableFromLink(
        mutation,
        {
          ...context,
          optimisticResponse,
        },
        variables,
        false,
      ).subscribe({
        next(result: ExecutionResult) {
          if (graphQLResultHasError(result) && errorPolicy === 'none') {
            error = new ApolloError({
              graphQLErrors: result.errors,
            });
            return;
          }

          self.mutationStore.markMutationResult(mutationId);

          if (fetchPolicy !== 'no-cache') {
            self.dataStore.markMutationResult({
              mutationId,
              result,
              document: mutation,
              variables,
              updateQueries: generateUpdateQueriesInfo(),
              update: updateWithProxyFn,
            });
          }

          storeResult = result as FetchResult<t>;
        },

        error(err: Error) {
          self.mutationStore.markMutationError(mutationId, err);
          self.dataStore.markMutationComplete({
            mutationId,
            optimisticResponse,
          });
          self.broadcastQueries();
          self.setQuery(mutationId, () => ({ document: null }));
          reject(
            new ApolloError({
              networkError: err,
            }),
          );
        },

        complete() {
          if (error) {
            self.mutationStore.markMutationError(mutationId, error);
          }

          self.dataStore.markMutationComplete({
            mutationId,
            optimisticResponse,
          });

          self.broadcastQueries();

          if (error) {
            reject(error);
            return;
          }

          // allow for conditional refetches
          // XXX do we want to make this the only API one day?
          if (typeof refetchQueries === 'function') {
            refetchQueries = refetchQueries(storeResult as ExecutionResult);
          }

          const refetchQueryPromises: Promise<
            ApolloQueryResult<any>[] | ApolloQueryResult<{}>
          >[] = [];

          if (isNonEmptyArray(refetchQueries)) {
            refetchQueries.forEach(refetchQuery => {
              if (typeof refetchQuery === 'string') {
                self.queries.forEach(({ observableQuery }) => {
                  if (
                    observableQuery &&
                    observableQuery.queryName === refetchQuery
                  ) {
                    refetchQueryPromises.push(observableQuery.refetch());
                  }
                });
              } else {
                const queryOptions: QueryOptions = {
                  query: refetchQuery.query,
                  variables: refetchQuery.variables,
                  fetchPolicy: 'network-only',
                };

                if (refetchQuery.context) {
                  queryOptions.context = refetchQuery.context;
                }

                refetchQueryPromises.push(self.query(queryOptions));
              }
            });
          }

          Promise.all(
            awaitRefetchQueries ? refetchQueryPromises : [],
          ).then(() => {
            self.setQuery(mutationId, () => ({ document: null }));

            if (
              errorPolicy === 'ignore' &&
              storeResult &&
              graphQLResultHasError(storeResult)
            ) {
              delete storeResult.errors;
            }

            resolve(storeResult!);
          });
        },
      });
    });
  }

  public async fetchQuery<t>(
    queryId: chuỗi,
    tùy chọn: WatchQueryOptions,
    fetchType?: FetchType,
    Điều này cho phép chúng tôi theo dõi xem đây có phải là truy vấn được tạo ra bởi 'fetchMore' hay không
    Gọi cho một truy vấn khác. Chúng tôi cần dữ liệu này để tính toán 'fetchMore'
    Trạng thái mạng cho truy vấn này đang tìm nạp.
    fetchMoreForQueryId?: chuỗi,
  ): Lời hứa<fetchresult<t>> {
    const {
      metadata = null,
      fetchPolicy = 'cache-first', // cache-first is the default fetch policy.
      context = {},
    } = options;

    const query = this.transform(options.query).document;

    let variables = this.getVariables(query, options.variables);

    if (this.transform(query).hasClientExports) {
      variables = await this.localState.addExportedVariables(query, variables, context);
    }

    options = { ...options, variables };

    let storeResult: any;
    const isNetworkOnly =
      fetchPolicy === 'network-only' || fetchPolicy === 'no-cache';
    let needToFetch = isNetworkOnly;

    // Unless we are completely skipping the cache, we want to diff the query
    // against the cache before we fetch it from the network interface.
    if (!isNetworkOnly) {
      const { complete, result } = this.dataStore.getCache().diff({
        query,
        variables,
        returnPartialData: true,
        optimistic: false,
      });

      // If we're in here, only fetch if we have missing fields
      needToFetch = !complete || fetchPolicy === 'cache-and-network';
      storeResult = result;
    }

    let shouldFetch =
      needToFetch && fetchPolicy !== 'cache-only' && fetchPolicy !== 'standby';

    // we need to check to see if this is an operation that uses the @live directive
    if (hasDirectives(['live'], query)) shouldFetch = true;

    const requestId = this.idCounter++;

    // set up a watcher to listen to cache updates
    const cancel = fetchPolicy !== 'no-cache'
      ? this.updateQueryWatch(queryId, query, options)
      : undefined;

    // Initialize query in store with unique requestId
    this.setQuery(queryId, () => ({
      document: query,
      lastRequestId: requestId,
      invalidated: true,
      cancel,
    }));

    this.invalidate(fetchMoreForQueryId);

    this.queryStore.initQuery({
      queryId,
      document: query,
      storePreviousVariables: shouldFetch,
      variables,
      isPoll: fetchType === FetchType.poll,
      isRefetch: fetchType === FetchType.refetch,
      metadata,
      fetchMoreForQueryId,
    });

    this.broadcastQueries();

    if (shouldFetch) {
      const networkResult = this.fetchRequest<t>({
        requestId,
        queryId,
        document: query,
        options,
        fetchMoreForQueryId,
      }).catch(error => {
        // This is for the benefit of `refetch` promises, which currently don't get their errors
        // through the store like watchQuery observers do
        if (isApolloError(error)) {
          throw error;
        } else {
          if (requestId >= this.getQuery(queryId).lastRequestId) {
            this.queryStore.markQueryError(queryId, error, fetchMoreForQueryId);
            this.invalidate(queryId);
            this.invalidate(fetchMoreForQueryId);
            this.broadcastQueries();
          }
          throw new ApolloError({ networkError: error });
        }
      });

      // we don't return the promise for cache-and-network since it is already
      // returned below from the cache
      if (fetchPolicy !== 'cache-and-network') {
        return networkResult;
      }

      // however we need to catch the error so it isn't unhandled in case of
      // network error
      networkResult.catch(() => {});
    }

    // If there is no part of the query we need to fetch from the server (or,
    // fetchPolicy is cache-only), we just write the store result as the final result.
    this.queryStore.markQueryResultClient(queryId, !shouldFetch);
    this.invalidate(queryId);
    this.invalidate(fetchMoreForQueryId);

    if (this.transform(query).hasForcedResolvers) {
      return this.localState.runResolvers({
        document: query,
        remoteResult: { data: storeResult },
        context,
        variables,
        onlyRunForcedResolvers: true,
      }).then((result: FetchResult<t>) => {
        this.markQueryResult(
          queryId,
          result,
          options,
          fetchMoreForQueryId,
        );
        this.broadcastQueries();
        return result;
      });
    }

    this.broadcastQueries();

    // If we have no query to send to the server, we should return the result
    // found within the store.
    return { data: storeResult };
  }

  private markQueryResult(
    queryId: string,
    result: ExecutionResult,
    {
      fetchPolicy,
      variables,
      errorPolicy,
    }: WatchQueryOptions,
    fetchMoreForQueryId?: string,
  ) {
    if (fetchPolicy === 'no-cache') {
      this.setQuery(queryId, () => ({
        newData: { result: result.data, complete: true },
      }));
    } else {
      this.dataStore.markQueryResult(
        result,
        this.getQuery(queryId).document!,
        variables,
        fetchMoreForQueryId,
        errorPolicy === 'ignore' || errorPolicy === 'all',
      );
    }
  }

  // Returns a query listener that will update the given observer based on the
  // results (or lack thereof) for a particular query.
  public queryListenerForObserver<t>(
    queryId: chuỗi,
    tùy chọn: WatchQueryOptions,
    Người quan sát: Người quan sát<apolloqueryresult<t>>,
  ): QueryListener {
    function invoke(method: 'next' | 'error', argument: any) {
      if (observer[method]) {
        try {
          observer[method]!(argument);
        } catch (e) {
          invariant.error(e);
        }
      } else if (method === 'error') {
        invariant.error(argument);
      }
    }

    return (
      queryStoreValue: QueryStoreValue,
      newData?: Cache.DiffResult<t>,
    ) => {
      // we're going to take a look at the data, so the query is no longer invalidated
      this.invalidate(queryId, false);

      // The query store value can be undefined in the event of a store
      // reset.
      if (!queryStoreValue) return;

      const { observableQuery, document } = this.getQuery(queryId);

      const fetchPolicy = observableQuery
        ? observableQuery.options.fetchPolicy
        : options.fetchPolicy;

      // don't watch the store for queries on standby
      if (fetchPolicy === 'standby') return;

      const loading = isNetworkRequestInFlight(queryStoreValue.networkStatus);
      const lastResult = observableQuery && observableQuery.getLastResult();

      const networkStatusChanged = !!(
        lastResult &&
        lastResult.networkStatus !== queryStoreValue.networkStatus
      );

      const shouldNotifyIfLoading =
        options.returnPartialData ||
        (!newData && queryStoreValue.previousVariables) ||
        (networkStatusChanged && options.notifyOnNetworkStatusChange) ||
        fetchPolicy === 'cache-only' ||
        fetchPolicy === 'cache-and-network';

      if (loading && !shouldNotifyIfLoading) {
        return;
      }

      const hasGraphQLErrors = isNonEmptyArray(queryStoreValue.graphQLErrors);

      const errorPolicy: ErrorPolicy = observableQuery
        && observableQuery.options.errorPolicy
        || options.errorPolicy
        || 'none';

      // If we have either a GraphQL error or a network error, we create
      // an error and tell the observer about it.
      if (errorPolicy === 'none' && hasGraphQLErrors || queryStoreValue.networkError) {
        return invoke('error', new ApolloError({
          graphQLErrors: queryStoreValue.graphQLErrors,
          networkError: queryStoreValue.networkError,
        }));
      }

      try {
        let data: any;
        let isMissing: boolean;

        if (newData) {
          // As long as we're using the cache, clear out the latest
          // `newData`, since it will now become the current data. We need
          // to keep the `newData` stored with the query when using
          // `no-cache` since `getCurrentQueryResult` attemps to pull from
          // `newData` first, following by trying the cache (which won't
          // find a hit for `no-cache`).
          if (fetchPolicy !== 'no-cache' && fetchPolicy !== 'network-only') {
            this.setQuery(queryId, () => ({ newData: null }));
          }

          data = newData.result;
          isMissing = !newData.complete;
        } else {
          const lastError = observableQuery && observableQuery.getLastError();
          const errorStatusChanged =
            errorPolicy !== 'none' &&
            (lastError && lastError.graphQLErrors) !==
              queryStoreValue.graphQLErrors;

          if (lastResult && lastResult.data && !errorStatusChanged) {
            data = lastResult.data;
            isMissing = false;
          } else {
            const diffResult = this.dataStore.getCache().diff({
              query: document as DocumentNode,
              variables:
                queryStoreValue.previousVariables ||
                queryStoreValue.variables,
              returnPartialData: true,
              optimistic: true,
            });

            data = diffResult.result;
            isMissing = !diffResult.complete;
          }
        }

        // If there is some data missing and the user has told us that they
        // do not tolerate partial data then we want to return the previous
        // result and mark it as stale.
        const stale = isMissing && !(
          options.returnPartialData ||
          fetchPolicy === 'cache-only'
        );

        const resultFromStore: ApolloQueryResult<t> = {
          data: stale ? lastResult && lastResult.data : data,
          loading,
          networkStatus: queryStoreValue.networkStatus,
          stale,
        };

        // if the query wants updates on errors we need to add it to the result
        if (errorPolicy === 'all' && hasGraphQLErrors) {
          resultFromStore.errors = queryStoreValue.graphQLErrors;
        }

        invoke('next', resultFromStore);

      } catch (networkError) {
        invoke('error', new ApolloError({ networkError }));
      }
    };
  }

  private transformCache = new (canUseWeakMap ? WeakMap : Map)<
    DocumentNode,
    Readonly<{
      document: Readonly<documentnode>;
      hasClientExports: boolean;
      hasForcedResolvers: boolean;
      clientQuery: Chỉ đọc<documentnode> | không;
      serverQuery: Chỉ đọc<documentnode> | không;
      defaultVars: Chỉ đọc<operationvariables>;
    }>
  >();

  public transform(document: DocumentNode) {
    const { transformCache } = this;

    if (!transformCache.has(document)) {
      const cache = this.dataStore.getCache();
      const transformed = cache.transformDocument(document);
      const forLink = removeConnectionDirectiveFromDocument(
        cache.transformForLink(transformed));

      const clientQuery = this.localState.clientQuery(transformed);
      const serverQuery = this.localState.serverQuery(forLink);

      const cacheEntry = {
        document: transformed,
        // TODO These two calls (hasClientExports and shouldForceResolvers)
        // could probably be merged into a single traversal.
        hasClientExports: hasClientExports(transformed),
        hasForcedResolvers: this.localState.shouldForceResolvers(transformed),
        clientQuery,
        serverQuery,
        defaultVars: getDefaultValues(
          getOperationDefinition(transformed)
        ) as OperationVariables,
      };

      const add = (doc: DocumentNode | null) => {
        if (doc && !transformCache.has(doc)) {
          transformCache.set(doc, cacheEntry);
        }
      }
      // Add cacheEntry to the transformCache using several different keys,
      // since any one of these documents could end up getting passed to the
      // transform method again in the future.
      add(document);
      add(transformed);
      add(clientQuery);
      add(serverQuery);
    }

    return transformCache.get(document)!;
  }

  private getVariables(
    document: DocumentNode,
    variables?: OperationVariables,
  ): OperationVariables {
    return {
      ...this.transform(document).defaultVars,
      ...variables,
    };
  }

  // The shouldSubscribe option is a temporary fix that tells us whether watchQuery was called
  // directly (i.e. through ApolloClient) or through the query method within QueryManager.
  // Currently, the query method uses watchQuery in order to handle non-network errors correctly
  // but we don't want to keep track observables issued for the query method since those aren't
  // supposed to be refetched in the event of a store reset. Once we unify error handling for
  // network errors and non-network errors, the shouldSubscribe option will go away.

  public watchQuery<t, TVariables="OperationVariables">(
    tùy chọn: WatchQueryOptions,
    nênĐăng ký = đúng,
  ): ObservableQuery<t, TVariables=""> {
    invariant(
      options.fetchPolicy !== 'standby',
      'client.watchQuery cannot be called with fetchPolicy set to "standby"',
    );

    // assign variable default values if supplied
    options.variables = this.getVariables(options.query, options.variables);

    if (typeof options.notifyOnNetworkStatusChange === 'undefined') {
      options.notifyOnNetworkStatusChange = false;
    }

    let transformedOptions = { ...options } as WatchQueryOptions<tvariables>;

trả về ObservableQuery mới<t, TVariables="">({
      queryManager: this,
      options: transformedOptions,
      shouldSubscribe: shouldSubscribe,
    });
  }

  public query<t>(tùy chọn: QueryOptions): Hứa<apolloqueryresult<t>> {
    invariant(
      options.query,
      'query option is required. You must specify your GraphQL document ' +
        'in the query option.',
    );

    invariant(
      options.query.kind === 'Document',
      'You must wrap the query string in a "gql" tag.',
    );

    invariant(
      !(options as any).returnPartialData,
      'returnPartialData option only supported on watchQuery.',
    );

    invariant(
      !(options as any).pollInterval,
      'pollInterval option only supported on watchQuery.',
    );

    return new Promise<apolloqueryresult<t>>((giải quyết, từ chối) => {
      const watchedQuery = this.watchQuery<t>(options, false);
      this.fetchQueryRejectFns.set(`query:${watchedQuery.queryId}`, reject);
      watchedQuery
        .result()
        .then(resolve, reject)
        // Since neither resolve nor reject throw or return a value, this .then
        // handler is guaranteed to execute. Note that it doesn't really matter
        // when we remove the reject function from this.fetchQueryRejectFns,
        // since resolve and reject are mutually idempotent. In fact, it would
        // not be incorrect to let reject functions accumulate over time; it's
        // just a waste of memory.
        .then(() =>
          this.fetchQueryRejectFns.delete(`query:${watchedQuery.queryId}`),
        );
    });
  }

  public generateQueryId() {
    return String(this.idCounter++);
  }

  public stopQueryInStore(queryId: string) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.broadcastQueries();
  }

  private stopQueryInStoreNoBroadcast(queryId: string) {
    this.stopPollingQuery(queryId);
    this.queryStore.stopQuery(queryId);
    this.invalidate(queryId);
  }

  public addQueryListener(queryId: string, listener: QueryListener) {
    this.setQuery(queryId, ({ listeners }) => {
      listeners.add(listener);
      return { invalidated: false };
    });
  }

  public updateQueryWatch(
    queryId: string,
    document: DocumentNode,
    options: WatchQueryOptions,
  ) {
    const { cancel } = this.getQuery(queryId);
    if (cancel) cancel();
    const previousResult = () => {
      let previousResult = null;
      const { observableQuery } = this.getQuery(queryId);
      if (observableQuery) {
        const lastResult = observableQuery.getLastResult();
        if (lastResult) {
          previousResult = lastResult.data;
        }
      }

      return previousResult;
    };
    return this.dataStore.getCache().watch({
      query: document as DocumentNode,
      variables: options.variables,
      optimistic: true,
      previousResult,
      callback: newData => {
        this.setQuery(queryId, () => ({ invalidated: true, newData }));
      },
    });
  }

  // Adds an ObservableQuery to this.observableQueries and to this.observableQueriesByName.
  public addObservableQuery<t>(
    queryId: chuỗi,
    observableQuery: ObservableQuery<t>,
  ) {
    this.setQuery(queryId, () => ({ observableQuery }));
  }

  public removeObservableQuery(queryId: string) {
    const { cancel } = this.getQuery(queryId);
    this.setQuery(queryId, () => ({ observableQuery: null }));
    if (cancel) cancel();
  }

  public clearStore(): Promise<void> {
    // Before we have sent the reset action to the store,
    // we can no longer rely on the results returned by in-flight
    // requests since these may depend on values that previously existed
    // in the data portion of the store. So, we cancel the promises and observers
    // that we have issued so far and not yet resolved (in the case of
    // queries).
    this.fetchQueryRejectFns.forEach(reject => {
      reject(new InvariantError(
        'Store reset while query was in flight (not completed in link chain)',
      ));
    });

    const resetIds: string[] = [];
    this.queries.forEach(({ observableQuery }, queryId) => {
      if (observableQuery) resetIds.push(queryId);
    });

    this.queryStore.reset(resetIds);
    this.mutationStore.reset();

    // begin removing data from the store
    return this.dataStore.reset();
  }

  public resetStore(): Promise<apolloqueryresult<any>[]> {
    // Similarly, we have to have to refetch each of the queries currently being
    // observed. We refetch instead of error'ing on these since the assumption is that
    // resetting the store doesn't eliminate the need for the queries currently being
    // watched. If there is an existing query in flight when the store is reset,
    // the promise for it will be rejected and its results will not be written to the
    // store.
    return this.clearStore().then(() => {
      return this.reFetchObservableQueries();
    });
  }

  public reFetchObservableQueries(
    includeStandby: boolean = false,
  ): Promise<apolloqueryresult<any>[]> {
    const observableQueryPromises: Promise<apolloqueryresult<any>>[] = [];

    this.queries.forEach(({ observableQuery }, queryId) => {
      if (observableQuery) {
        const fetchPolicy = observableQuery.options.fetchPolicy;

        observableQuery.resetLastResults();
        if (
          fetchPolicy !== 'cache-only' &&
          (includeStandby || fetchPolicy !== 'standby')
        ) {
          observableQueryPromises.push(observableQuery.refetch());
        }

        this.setQuery(queryId, () => ({ newData: null }));
        this.invalidate(queryId);
      }
    });

    this.broadcastQueries();

    return Promise.all(observableQueryPromises);
  }

  public observeQuery<t>(
    queryId: chuỗi,
    tùy chọn: WatchQueryOptions,
    Người quan sát: Người quan sát<apolloqueryresult<t>>,
  ) {
    this.addQueryListener(
      queryId,
      this.queryListenerForObserver(queryId, options, observer),
    );
    return this.fetchQuery<t>(queryId, tùy chọn);
  }

bắt đầu công khaiTruy vấn<t>(
    queryId: string,
    options: WatchQueryOptions,
    listener: QueryListener,
  ) {
    invariant.warn("The QueryManager.startQuery method has been deprecated");

    this.addQueryListener(queryId, listener);

    this.fetchQuery<t>(queryId, tùy chọn)
      'fetchQuery' trả về một Promise. Trong trường hợp thất bại, nó nên được caucht hoặc nếu không
      bảng điều khiển sẽ hiển thị thông báo 'Chưa bị bắt (đang hứa)'. Bỏ qua lỗi ngay bây giờ.
      .catch(() => không xác định);

trả về queryId;
  }

bắt đầu công khaiĐồ thịQLSubscription<t =="" any="">({
    query,
    fetchPolicy,
    variables,
  }: SubscriptionOptions): Observable<fetchresult<t>> {
    query = this.transform(query).document;
    variables = this.getVariables(query, variables);

    const makeObservable = (variables: OperationVariables) =>
      this.getObservableFromLink<t>(
        query,
        {},
        variables,
        false,
      ).map(result => {
        if (!fetchPolicy || fetchPolicy !== 'no-cache') {
          this.dataStore.markSubscriptionResult(
            result,
            query,
            variables,
          );
          this.broadcastQueries();
        }

        if (graphQLResultHasError(result)) {
          throw new ApolloError({
            graphQLErrors: result.errors,
          });
        }

        return result;
      });

    if (this.transform(query).hasClientExports) {
      const observablePromise = this.localState.addExportedVariables(
        query,
        variables,
      ).then(makeObservable);

      return new Observable<fetchresult<t>>(observer => {
        let sub: Subscription | null = null;
        observablePromise.then(
          observable => sub = observable.subscribe(observer),
          observer.error,
        );
        return () => sub && sub.unsubscribe();
      });
    }

    return makeObservable(variables);
  }

  public stopQuery(queryId: string) {
    this.stopQueryNoBroadcast(queryId);
    this.broadcastQueries();
  }

  private stopQueryNoBroadcast(queryId: string) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.removeQuery(queryId);
  }

  public removeQuery(queryId: string) {
    // teardown all links
    // Both `QueryManager.fetchRequest` and `QueryManager.query` create separate promises
    // that each add their reject functions to fetchQueryRejectFns.
    // A query created with `QueryManager.query()` could trigger a `QueryManager.fetchRequest`.
    // The same queryId could have two rejection fns for two promises
    this.fetchQueryRejectFns.delete(`query:${queryId}`);
    this.fetchQueryRejectFns.delete(`fetchRequest:${queryId}`);
    this.getQuery(queryId).subscriptions.forEach(x => x.unsubscribe());
    this.queries.delete(queryId);
  }

  public getCurrentQueryResult<t>(
    observableQuery: ObservableQuery<t>,
    optimistic: boolean = true,
  ): {
    data: T | undefined;
    partial: boolean;
  } {
    const { variables, query, fetchPolicy, returnPartialData } = observableQuery.options;
    const lastResult = observableQuery.getLastResult();
    const { newData } = this.getQuery(observableQuery.queryId);

    if (newData && newData.complete) {
      return { data: newData.result, partial: false };
    }

    if (fetchPolicy === 'no-cache' || fetchPolicy === 'network-only') {
      return { data: undefined, partial: false };
    }

    const { result, complete } = this.dataStore.getCache().diff<t>({
      query,
      variables,
      previousResult: lastResult ? lastResult.data : undefined,
      returnPartialData: true,
      optimistic,
    });

    return {
      data: (complete || returnPartialData) ? result : void 0,
      partial: !complete,
    };
  }

  public getQueryWithPreviousResult<tdata, TVariables="OperationVariables">(
    queryIdOrObservable: chuỗi | ObservableQuery<tdata, TVariables="">,
  ): {
    previousResult: any;
    variables: TVariables | undefined;
    document: DocumentNode;
  } {
    let observableQuery: ObservableQuery<tdata, any="">;
    if (typeof queryIdOrObservable === 'string') {
      const { observableQuery: foundObserveableQuery } = this.getQuery(
        queryIdOrObservable,
      );
      invariant(
        foundObserveableQuery,
        `ObservableQuery with this id doesn't exist: ${queryIdOrObservable}`
      );
      observableQuery = foundObserveableQuery!;
    } else {
      observableQuery = queryIdOrObservable;
    }

    const { variables, query } = observableQuery.options;
    return {
      previousResult: this.getCurrentQueryResult(observableQuery, false).data,
      variables,
      document: query,
    };
  }

  public broadcastQueries() {
    this.onBroadcast();
    this.queries.forEach((info, id) => {
      if (info.invalidated) {
        info.listeners.forEach(listener => {
          // it's possible for the listener to be undefined if the query is being stopped
          // See here for more detail: https://github.com/apollostack/apollo-client/issues/231
          if (listener) {
            listener(this.queryStore.get(id), info.newData);
          }
        });
      }
    });
  }

  public getLocalState(): LocalState<tstore> {
    return this.localState;
  }

  private inFlightLinkObservables = new Map<
    DocumentNode,
    Map<string, Observable<FetchResult="">>
  >();

riêng tưgetObservableFromLink<t =="" any="">(
    truy vấn: DocumentNode,
    bối cảnh: bất kỳ,
    biến?: OperationVariables,
    chống trùng lặp: boolean = this.queryDeduplication,
  ): Có thể quan sát được<fetchresult<t>> {
    let observable: Observable<fetchresult<t>>;

    const { serverQuery } = this.transform(query);
    if (serverQuery) {
      const { inFlightLinkObservables, link } = this;

      const operation = {
        query: serverQuery,
        variables,
        operationName: getOperationName(serverQuery) || void 0,
        context: this.prepareContext({
          ...context,
          forceFetch: !deduplication
        }),
      };

      context = operation.context;

      if (deduplication) {
        const byVariables = inFlightLinkObservables.get(serverQuery) || new Map();
        inFlightLinkObservables.set(serverQuery, byVariables);

        const varJson = JSON.stringify(variables);
        observable = byVariables.get(varJson);

        if (!observable) {
          byVariables.set(
            varJson,
            observable = multiplex(
              execute(link, operation) as Observable<fetchresult<t>>
            )
          );

          const cleanup = () => {
            byVariables.delete(varJson);
            if (!byVariables.size) inFlightLinkObservables.delete(serverQuery);
            cleanupSub.unsubscribe();
          };

          const cleanupSub = observable.subscribe({
            next: cleanup,
            error: cleanup,
            complete: cleanup,
          });
        }

      } else {
        observable = multiplex(execute(link, operation) as Observable<fetchresult<t>>);
      }
    } else {
      observable = Observable.of({ data: {} } as FetchResult<t>);
      context = this.prepareContext(context);
    }

    const { clientQuery } = this.transform(query);
    if (clientQuery) {
      observable = asyncMap(observable, result => {
        return this.localState.runResolvers({
          document: clientQuery,
          remoteResult: result,
          context,
          variables,
        });
      });
    }

    return observable;
  }

  // Takes a request id, query id, a query document and information associated with the query
  // and send it to the network interface. Returns
  // a promise for the result associated with that request.
  private fetchRequest<t>({
    requestId,
    queryId,
    document,
    options,
    fetchMoreForQueryId,
  }: {
    requestId: number;
    queryId: string;
    document: DocumentNode;
    options: WatchQueryOptions;
    fetchMoreForQueryId?: string;
  }): Promise<fetchresult<t>> {
    const { variables, errorPolicy = 'none', fetchPolicy } = options;
    let resultFromStore: any;
    let errorsFromStore: any;

    return new Promise<apolloqueryresult<t>>((resolve, reject) => {
      const observable = this.getObservableFromLink(
        document,
        options.context,
        variables,
      );

      const fqrfId = `fetchRequest:${queryId}`;
      this.fetchQueryRejectFns.set(fqrfId, reject);

      const cleanup = () => {
        this.fetchQueryRejectFns.delete(fqrfId);
        this.setQuery(queryId, ({ subscriptions }) => {
          subscriptions.delete(subscription);
        });
      };

      const subscription = observable.map((result: ExecutionResult) => {
        if (requestId >= this.getQuery(queryId).lastRequestId) {
          this.markQueryResult(
            queryId,
            result,
            options,
            fetchMoreForQueryId,
          );

          this.queryStore.markQueryResult(
            queryId,
            result,
            fetchMoreForQueryId,
          );

          this.invalidate(queryId);
          this.invalidate(fetchMoreForQueryId);

          this.broadcastQueries();
        }

        if (errorPolicy === 'none' && isNonEmptyArray(result.errors)) {
          return reject(new ApolloError({
            graphQLErrors: result.errors,
          }));
        }

        if (errorPolicy === 'all') {
          errorsFromStore = result.errors;
        }

        if (fetchMoreForQueryId || fetchPolicy === 'no-cache') {
          // We don't write fetchMore results to the store because this would overwrite
          // the original result in case an @connection directive is used.
          resultFromStore = result.data;
        } else {
          // ensure result is combined with data already in store
          const { result, complete } = this.dataStore.getCache().diff<t>({
            variables,
            query: document,
            optimistic: false,
            returnPartialData: true,
          });

          if (complete || options.returnPartialData) {
            resultFromStore = result;
          }
        }
      }).subscribe({
        error(error: ApolloError) {
          cleanup();
          reject(error);
        },

        complete() {
          cleanup();
          resolve({
            data: resultFromStore,
            errors: errorsFromStore,
            loading: false,
            networkStatus: NetworkStatus.ready,
            stale: false,
          });
        },
      });

      this.setQuery(queryId, ({ subscriptions }) => {
        subscriptions.add(subscription);
      });
    });
  }

  private getQuery(queryId: string) {
    return (
      this.queries.get(queryId) || {
        listeners: new Set<querylistener>(),
        Vô hiệu: false,
        Tài liệu: null,
        newData: null,
        lastRequestId: 1,
        observableQuery: null,
        đăng ký: Bộ mới<subscription>(),
      }
    );
  }

bộ riêng tưTruy vấn<t extends="" keyof="" QueryInfo="">(
    queryId: chuỗi,
    updater: (prev: QueryInfo) => Chọn<queryinfo, T=""> | void,
  ) {
    const prev = this.getQuery(queryId);
    const newInfo = { ...prev, ...updater(prev) };
    this.queries.set(queryId, newInfo);
  }

  private invalidate(
    queryId: string | undefined,
    invalidated = true,
  ) {
    if (queryId) {
      this.setQuery(queryId, () => ({ invalidated }));
    }
  }

  private prepareContext(context = {}) {
    const newContext = this.localState.prepareContext(context);
    return {
      ...newContext,
      clientAwareness: this.clientAwareness,
    };
  }

  public checkInFlight(queryId: string) {
    const query = this.queryStore.get(queryId);

    return (
      query &&
      query.networkStatus !== NetworkStatus.ready &&
      query.networkStatus !== NetworkStatus.error
    );
  }

  // Map from client ID to { interval, options }.
  private pollingInfoByQueryId = new Map<string, {="" interval:="" number;="" timeout:="" NodeJS.Timeout;="" options:="" WatchQueryOptions;="" }="">();

  public startPollingQuery(
    options: WatchQueryOptions,
    queryId: string,
    listener?: QueryListener,
  ): string {
    const { pollInterval } = options;

    invariant(
      pollInterval,
      'Attempted to start a polling query without a polling interval.',
    );

    // Do not poll in SSR mode
    if (!this.ssrMode) {
      let info = this.pollingInfoByQueryId.get(queryId)!;
      if (!info) {
        this.pollingInfoByQueryId.set(queryId, (info = {} as any));
      }

      info.interval = pollInterval!;
      info.options = {
        ...options,
        fetchPolicy: 'network-only',
      };

      const maybeFetch = () => {
        const info = this.pollingInfoByQueryId.get(queryId);
        if (info) {
          if (this.checkInFlight(queryId)) {
            poll();
          } else {
            this.fetchQuery(queryId, info.options, FetchType.poll).then(
              poll,
              poll,
            );
          }
        }
      };

      const poll = () => {
        const info = this.pollingInfoByQueryId.get(queryId);
        if (info) {
          clearTimeout(info.timeout);
          info.timeout = setTimeout(maybeFetch, info.interval);
        }
      };

      if (listener) {
        this.addQueryListener(queryId, listener);
      }

      poll();
    }

    return queryId;
  }

  public stopPollingQuery(queryId: string) {
    this.pollingInfoByQueryId.delete(queryId);
  }
}
</string,></queryinfo,></t></subscription></querylistener></t></apolloqueryresult<t></fetchresult<t></t></t></fetchresult<t></fetchresult<t></fetchresult<t></fetchresult<t></t></string,></tstore></tdata,></tdata,></tdata,></t></t></t></fetchresult<t></t></fetchresult<t></t></t></t></t></apolloqueryresult<t></t></apolloqueryresult<any></apolloqueryresult<any></apolloqueryresult<any></void></t></t></t></apolloqueryresult<t></apolloqueryresult<t></t></t,></tvariables></t,></t,></operationvariables></documentnode></documentnode></documentnode></t></t></apolloqueryresult<t></t></t></t></fetchresult<t></t></any></t></t></fetchresult<t></t></tstore></string,></tstore></string,></string,></tstore></string,></tstore></tstore></subscription></any></any></querylistener>