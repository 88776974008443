/**
 * Thêm các thuộc tính của một hoặc nhiều đối tượng nguồn vào một đối tượng đích. Hoạt động chính xác như
 * 'Object.assign', nhưng là một tiện ích để duy trì hỗ trợ cho IE 11.
 *
 * @see https://github.com/apollostack/apollo-client/pull/1009
 */
Chức năng xuất gán<a, B="">(a: A, b: B): A & B;
Chức năng xuất gán<a, B,="" C="">(a: A, b: B, c: C): A & B & C;
Chức năng xuất gán<a, B,="" C,="" D="">(a: A, b: B, c: C, d: D): A & B & C & D;
Chức năng xuất gán<a, B,="" C,="" D,="" E="">(
  a: Đáp,
  b: B,
  c: C,
  d: D,
  e: E,
): A & B & C & D & E;
Chức năng xuất khẩu gán (mục tiêu: bất kỳ, ... nguồn: Mảng<any>): any;
export function assign(
  target: { [key: string]: any },
  ...sources: Array<{ [key: string]: any }>
): { [key: string]: any } {
  sources.forEach(source => {
    if (typeof source === 'undefined' || source === null) {
      return;
    }
    Object.keys(source).forEach(key => {
      target[key] = source[key];
    });
  });
  return target;
}
</any></a,></a,></a,></a,>