import React from 'react';
import { Typography } from './typography';
import { TypographyProps } from './typography-props';
import { TypographyVariant } from '@mint/core';
import styled from 'styled-components';

const makeVariantComponent = (
  variant: TypographyVariant,
  displayName: string,
) => {
  const base = React.forwardRef<any, TypographyProps="">(
    (props, ref): React.ReactElement => (
      <typography variant="{variant}" {...props}="" ref="{ref}"></typography>
    ),
  );
  base.displayName = displayName;
  trả về kiểu (cơ sở)'';
};

xuất const BodyText = makeVariantComponent('body', 'BodyText');

xuất const ButtonText = makeVariantComponent('nút', 'ButtonText');

xuất const CaptionText = makeVariantComponent('caption', 'CaptionText');

xuất const ControlText = makeVariantComponent('control', 'ControlText');

xuất const DenseText = makeVariantComponent('dense', 'DenseText');

xuất const HeadingOneText = makeVariantComponent('h1', 'HeadingOneText');

xuất const HeadingTwoText = makeVariantComponent('h2', 'HeadingTwoText');

xuất const HeadingThreeText = makeVariantComponent('h3', 'HeadingThreeText');

xuất const HeadingFourText = makeVariantComponent('h4', 'HeadingFourText');

xuất const HeadingFiveText = makeVariantComponent('h5', 'HeadingFiveText');

xuất const HeadingSixText = makeVariantComponent('h6', 'HeadingSixText');

xuất const HelperText = makeVariantComponent('helper', 'HelperText');

xuất const OverlineText = makeVariantComponent('overline', 'OverlineText');

xuất const SubtitleText = makeVariantComponent('phụ đề', 'SubtitleText');
</any,>