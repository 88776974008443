import { DocumentNode } from 'graphql';
import { getFragmentQueryDocument } from 'apollo-utilities';

import { DataProxy, Cache } from './types';
import { justTypenameQuery, queryFromPojo, fragmentFromPojo } from './utils';

export type Transaction<t> = (c: ApolloCache<t>) => khoảng trống;

xuất lớp trừu tượng ApolloCache<tserialized> triển khai DataProxy {
  cần thiết để thực hiện
  API cốt lõi
  Đọc tóm tắt công khai<t, TVariables="any">(
    truy vấn: Cache.ReadOptions<tvariables>,
  ): T | không;
  Viết tóm tắt công khai<tresult =="" any,="" TVariables="any">(
    viết: Cache.WriteOptions<tresult, TVariables="">,
  ): vô hiệu;
  Tóm tắt công cộng khác nhau<t>(truy vấn: Cache.DiffOptions): Cache.DiffResult<t>;
  public abstract watch(watch: Cache.WatchOptions): () => void;
  Trục xuất trừu tượng công cộng<tvariables =="" any="">(
    truy vấn: Cache.EvictOptions<tvariables>,
  ): Cache.EvictionResult;
  public abstract reset(): Lời hứa<void>;

API intializer / offline / ssr
  /**
   * Thay thế trạng thái hiện có trong bộ nhớ cache (nếu có) bằng các giá trị được thể hiện bằng
   * 'Nhà nước nối tiếp'.
   *
   * Được gọi khi hydrating một bộ nhớ cache (kết xuất phía máy chủ, hoặc lưu trữ ngoại tuyến),
   * và cũng (có khả năng) trong quá trình tải lại nóng.
   */
  Khôi phục trừu tượng công cộng (
    Trạng thái nối tiếp: Đã nối tiếp hóa,
  ): ApolloCache<tserialized>;

/**
   * Hiển thị trạng thái hoàn chỉnh của bộ nhớ cache, ở định dạng có thể tuần tự hóa để khôi phục sau này.
   */
  trích đoạn trừu tượng công khai (lạc quan?: boolean): Trích dẫn lại;

API lạc quan
  public abstract removeOptimistic(id: chuỗi): void;

API giao dịch
  thực hiện trừu tượng công khaiGiao dịch(
    transaction: Giao dịch<tserialized>,
  ): vô hiệu;
  hồ sơ trừu tượng công khaiOptimisticGiao dịch(
    transaction: Giao dịch<tserialized>,
    id: string,
  ): void;

  // optional API
  public transformDocument(document: DocumentNode): DocumentNode {
    return document;
  }
  // experimental
  public transformForLink(document: DocumentNode): DocumentNode {
    return document;
  }

  // DataProxy API
  /**
   *
   * @param options
   * @param optimistic
   */
  public readQuery<querytype, TVariables="any">(
    tùy chọn: DataProxy.Query<tvariables>,
    optimistic: boolean = false,
  ): QueryType | null {
    return this.read({
      query: options.query,
      variables: options.variables,
      optimistic,
    });
  }

  public readFragment<fragmenttype, TVariables="any">(
    tùy chọn: DataProxy.Fragment<tvariables>,
    optimistic: boolean = false,
  ): FragmentType | null {
    return this.read({
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
      variables: options.variables,
      rootId: options.id,
      optimistic,
    });
  }

  public writeQuery<tdata =="" any,="" TVariables="any">(
    Tùy chọn: Cache.WriteQueryOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: 'ROOT_QUERY',
      result: options.data,
      query: options.query,
      variables: options.variables,
    });
  }

  public writeFragment<tdata =="" any,="" TVariables="any">(
    tùy chọn: Cache.WriteFragmentOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: options.id,
      result: options.data,
      variables: options.variables,
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
    });
  }

  public writeData<tdata =="" any="">({
    id,
    data,
  }: Cache.WriteDataOptions<tdata>): void {
    if (typeof id !== 'undefined') {
      let typenameResult = null;
      // Since we can't use fragments without having a typename in the store,
      // we need to make sure we have one.
      // To avoid overwriting an existing typename, we need to read it out first
      // and generate a fake one if none exists.
      try {
        typenameResult = this.read<any>({
          rootId: id,
          optimistic: false,
          query: justTypenameQuery,
        });
      } catch (e) {
        // Do nothing, since an error just means no typename exists
      }

      // tslint:disable-next-line
      const __typename =
        (typenameResult && typenameResult.__typename) || '__ClientData';

      // Add a type here to satisfy the inmemory cache
      const dataToWrite = Object.assign({ __typename }, data);

      this.writeFragment({
        id,
        fragment: fragmentFromPojo(dataToWrite, __typename),
        data: dataToWrite,
      });
    } else {
      this.writeQuery({ query: queryFromPojo(data), data });
    }
  }
}
</any></tdata></tdata></tdata,></tdata></tdata,></tdata></tvariables></fragmenttype,></tvariables></querytype,></tserialized></tserialized></tserialized></void></tvariables></tvariables></t></t></tresult,></tresult></tvariables></t,></tserialized></t></t>