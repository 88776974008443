/**
 * Chức năng tiện ích để áp dụng tên lớp.
 * @param nhập (các) chuỗi tên lớp, mảng hoặc bản đồ.
 */
xuất const className = (
  ... Đầu vào: Mảng<string |="" string[]="" boolean="" null="" undefined="">
): string =>
  input
    .reduce((curr: string[], val) => {
      if (Array.isArray(val)) {
        val.forEach(v => curr.push(v));
      } else if (typeof val === 'string') {
        curr.push(val.trim());
      }
      return curr;
    }, [])
    .join(' ');
</string>