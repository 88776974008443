import React, { useCallback, useState } from 'react';
import { bem, cx } from '@mint/core';
import { useFocus, useFormControlClassNames } from '../shared';
import { Input } from '../input';
import { TextInputProps } from './text-input-props';
import styled from 'styled-components';

const ROOT_CLASS_NAME = 'text-input';

/**
 * Text input component implementation.
 */
const Component = React.forwardRef<htmlinputelement, TextInputProps="">(
  (props, ref): React.ReactElement => {
    const { className, helperText, dense, onFocus, onBlur, ...rest } = props;

    const [focused, bindFocus] = useFocus({ onBlur, onFocus });
    const rootBem = bem(ROOT_CLASS_NAME);

    const inputProps = {
      className: cx(
        useFormControlClassNames(ROOT_CLASS_NAME, { ...props, focused }),
        dense && rootBem.dense(),
      ),
      ...bindFocus,
      ...rest,
      dense,
      helperText,
      ref,
    };

    return <input {...inputProps}="">;
  },
);

Component.displayName = 'TextInput';

/**
 * Trường nhập văn bản.
 */
xuất const TextInput = styled (Thành phần) '';
</htmlinputelement,>