// This simplified polyfill attempts to follow the ECMAScript Observable proposal.
// See https://github.com/zenparsing/es-observable
import { Observable as LinkObservable } from 'apollo-link';

export type Subscription = ZenObservable.Subscription;
export type Observer<t> = ZenObservable.Người quan sát<t>;

nhập $ $observable từ 'symbol-observable';

RXJS Interopt
lớp xuất khẩu Observable<t> mở rộng LinkObservable<t> {
  public [$$observable]() {
    return this;
  }

  public ['@@observable' as any]() {
    return this;
  }
}
</t></t></t></t>